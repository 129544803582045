import React from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
// import theme from "../../theme/theme.yaml";
import { Link } from "gatsby";

const Footer = (props) => {
  return (
    // <StyledFragment>
    <footer className="page-footer font-small bg-gradient-light pt-4">
      <div className="container-fluid text-center text-md-left">
        <div className="row">
          <div className="col-md-6 mt-md-0 mt-3">
            <h5 className="text-uppercase">PLAGIASHIELD</h5>
            {/* <p>Here you can use rows and columns to organize your footer content.</p> */}
          </div>
          <hr className="clearfix w-100 d-md-none pb-3" />

          <div className="col-md-3 mb-md-0 mb-3">
            {/* <h5 className="text-uppercase">Links</h5> */}

            <ul className="list-unstyled">
              <li className="mx-3 my-2">
                <Link to="/blog" key="blog" className="text-dark">
                  Blog
                </Link>
                {/* <a href="/Blog">Blog</a> */}
              </li>
              <li className="mx-3 my-2">
                <Link to="/pricing" key="pricing" className="text-dark">
                  Pricing
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/plagiarism-checker" key="plagiarism-checker" className="text-dark">
                  Plagiarism Checker
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/website-plagiarism-checker" key="website-plagiarism-checker" className="text-dark">
                  Website Plagiarism Checker
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/chatgpt-plagiarism-checker" key="chatgpt-plagiarism-checker" className="text-dark">
                  ChatGPT Plagiarism Checker
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3 mb-md-0 mb-3">
            {/* <h5 className="text-uppercase">Links</h5> */}

            <ul className="list-unstyled">
              <li className="mx-3 my-2">
                <Link to="/privacy" key="privacy" className="text-dark ">
                  Privacy Policy
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/terms" key="terms" className="text-dark">
                  Terms and Conditions
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/faq" key="faq" className="text-dark">
                  FAQ
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/press" key="press" className="text-dark">
                  Press
                </Link>
              </li>
              <li className="mx-3 my-2">
                <Link to="/affiliate-program" key="affiliate" className="text-dark">
                  Affiliate program
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">Copyright © 2019-2023 PlagiaShield</div>
    </footer>
    // </StyledFragment>
  );
};

export default Footer;
