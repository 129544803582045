exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-program-js": () => import("./../../../src/pages/affiliate-program.js" /* webpackChunkName: "component---src-pages-affiliate-program-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chatgpt-plagiarism-checker-js": () => import("./../../../src/pages/chatgpt-plagiarism-checker.js" /* webpackChunkName: "component---src-pages-chatgpt-plagiarism-checker-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-plagiarism-checker-js": () => import("./../../../src/pages/plagiarism-checker.js" /* webpackChunkName: "component---src-pages-plagiarism-checker-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-website-plagiarism-checker-js": () => import("./../../../src/pages/website-plagiarism-checker.js" /* webpackChunkName: "component---src-pages-website-plagiarism-checker-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-4-privacy-index-md": () => import("./../../../src/templates/PageTemplate2.js?__contentFilePath=/codebuild/output/src180236206/src/content/pages/4--privacy/index.md" /* webpackChunkName: "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-4-privacy-index-md" */),
  "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-5-terms-index-md": () => import("./../../../src/templates/PageTemplate2.js?__contentFilePath=/codebuild/output/src180236206/src/content/pages/5--terms/index.md" /* webpackChunkName: "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-5-terms-index-md" */),
  "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-6-faq-index-md": () => import("./../../../src/templates/PageTemplate2.js?__contentFilePath=/codebuild/output/src180236206/src/content/pages/6--faq/index.md" /* webpackChunkName: "component---src-templates-page-template-2-js-content-file-path-codebuild-output-src-180236206-src-content-pages-6-faq-index-md" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-03-20-public-beta-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-03-20--public-beta/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-03-20-public-beta-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-03-31-google-removal-tool-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-03-31--google-removal-tool/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-03-31-google-removal-tool-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-04-05-what-is-copyright-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-04-05--what-is-copyright/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-04-05-what-is-copyright-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-04-10-what-is-fair-use-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-04-10--what-is-fair-use/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-04-10-what-is-fair-use-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-09-13-copyright-law-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-09-13--copyright-law/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-09-13-copyright-law-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-09-19-copyright-infringement-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-09-19--copyright-infringement/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-09-19-copyright-infringement-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-08-copyright-notice-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-10-08--copyright-notice/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-08-copyright-notice-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-23-derivative-work-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-10-23--derivative-work/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-23-derivative-work-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-23-fair-dealing-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-10-23--fair-dealing/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-10-23-fair-dealing-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-11-19-copyright-case-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-11-19--copyright-case/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-11-19-copyright-case-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-11-19-types-of-plagiarism-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-11-19--types-of-plagiarism/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-11-19-types-of-plagiarism-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-12-06-consequences-of-plagiarism-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-12-06--consequences-of-plagiarism/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-12-06-consequences-of-plagiarism-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-12-06-plagiarism-seo-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2020-12-06--plagiarism-seo/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2020-12-06-plagiarism-seo-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2021-03-09-saas-study-2021-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2021-03-09--saas-study-2021/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2021-03-09-saas-study-2021-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2022-02-11-how-to-know-i-have-been-plagiarized-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2022-02-11--how-to-know-i-have-been-plagiarized/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2022-02-11-how-to-know-i-have-been-plagiarized-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2022-10-30-news-study-2022-index-mdx": () => import("./../../../src/templates/PostTemplate.js?__contentFilePath=/codebuild/output/src180236206/src/content/posts/2022-10-30--news-study-2022/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-codebuild-output-src-180236206-src-content-posts-2022-10-30-news-study-2022-index-mdx" */)
}

